import useSWR from 'swr';

import apiRequest from '@/services/apiRequest';
import { IMeta, ISearchRentals } from '@/services/types/search/rentals/id';
import { getCoreApi, getSearchApi } from '@/utility/getCoreApi';

const WISHLISTS_URL = `${getCoreApi()}/wishlists`;
const SEARCH_API_URL = `${getSearchApi()}/rentals`;

export interface IWishlist {
  cover_image_url: string;
  id: number;
  rental_count: number;
  title: string;
  user_role: string;
  users: number[]; // Array of user IDs
  user_details: {
    id: number;
    first_name?: string;
    last_name?: string;
    avatar_url?: string;
  }[];
  rentals: number[]; // Array of rental IDs
  start_date?: string;
  end_date?: string;
  ada?: boolean;
  pets?: boolean;
  number_of_adults?: number;
  number_of_children?: number;
  is_example?: boolean;
}

export interface IWishlistResponse {
  cover_image_url: string;
  title: string;
}

export interface IWishlistTokenResponse {
  token: string;
  wishlist_id: number;
}

export interface IWishlistCreateResponse extends IWishlistResponse {
  id: number;
}

/**
 * Control which wishlists to include:
 * - examples_only=false&exclude_examples=true: Get only user wishlists (default)
 * - examples_only=false&exclude_examples=false: Get user wishlists and example wishlists
 * - examples_only=true&exclude_examples=false: Get only example wishlists
 * - examples_only=true&exclude_examples=true: Not allowed
 */
interface GetWishlistsOptions {
  isAuthenticated: boolean;
  examplesOnly?: boolean;
  excludeExamples?: boolean;
}

export const useGetWishlists = ({
  isAuthenticated,
  examplesOnly = false,
  excludeExamples = false,
}: GetWishlistsOptions) => {
  const constructUrl = () => {
    if (!isAuthenticated) return null;

    const params = new URLSearchParams({
      examples_only: examplesOnly.toString(),
      exclude_examples: excludeExamples.toString(),
    });

    return `${WISHLISTS_URL}?${params.toString()}`;
  };

  return useSWR<IWishlist[]>(constructUrl(), (url: string) =>
    apiRequest<IWishlist[]>({ url, method: 'GET' }),
  );
};

export const useGetWishlistData = (wishlistId: number) => {
  return useSWR<IWishlist>(`${WISHLISTS_URL}/${wishlistId}`, (url: string) =>
    apiRequest<IWishlist>({ url, method: 'GET' }),
  );
};

interface IWishlistRentalsParams {
  rentalIds: number[];
  from?: string;
  to?: string;
  currency?: string;
  locale?: string;
}

export const useGetWishlistRentals = (params: IWishlistRentalsParams) => {
  const constructSearchUrl = () => {
    const urlParams = new URLSearchParams({
      currency: params.currency || '',
      locale: params.locale || '',
      raw_json: 'true',
      include_unavailable: 'true',
    });

    // Add rental IDs
    urlParams.append('ids', params.rentalIds.join(','));

    // Add dates if provided
    if (params.from && params.to) {
      urlParams.append('date[from]', params.from);
      urlParams.append('date[to]', params.to);
    }

    return `${SEARCH_API_URL}?${urlParams.toString()}`;
  };

  // Create a valid empty response object with proper typing
  const emptyResponse: ISearchRentals = {
    data: [],
    meta: {} as IMeta,
  };

  const searchUrl = params.rentalIds.length > 0 ? constructSearchUrl() : null;

  return useSWR<ISearchRentals>(
    searchUrl,
    url => (url ? fetch(url).then(res => res.json()) : emptyResponse),
    {
      // Return empty data when there are no rental IDs to prevent loading state
      fallbackData: params.rentalIds.length === 0 ? emptyResponse : undefined,
    },
  );
};
